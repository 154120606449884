

.introWrapper {


	.intro {
		font-size: 1.2em;
	}
	
	.introImage {
		width: 275px;
		height: 275px;
		margin-left: auto;
		margin-right: auto;
		display: block;
		border-radius: 50%;
	}

}


