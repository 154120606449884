h1 {
	text-align: center;
	font-family: 'Josefin Sans', sans-serif;
	font-size: 3.5em;
	margin-top: 70px;
	margin-bottom: 25px;

	a {
		color: #333333 !important;
	}

}

.tabs {
	display: block;
	justify-content:center;
	margin-bottom: 25px;

	h2 {
		font-size: 1.3em;
		font-family: 'Josefin Sans', sans-serif;	
		margin: 13px;
		margin-left: 15px;
		margin-right: 15px;

		a:visited { 
			color: #444444; 
			text-decoration: none;
		}
		a:link {
			color: #444444;
			text-decoration: none;
		}
	
		a:hover {
			color: #000000;
			text-decoration: none;
		}
	}

	.subtab {
		display:flex;
		justify-content: center;
	}
}

@media ( min-width: 768px ){
	.tabs {
		display: flex;
		justify-content: center;
	}	
}


