.footer {
	margin-top: 50px;
	margin-bottom: 60px;
	text-align: center;

	h4 {
		color: #444444;
		margin: 5px;
		font-size: 0.9em;
	}
}