
.News {
	display: flex;
	margin-bottom: 15px;

	.NewsDate {
		width: 95px;
	}

	h4 {
		margin: 0;
		// margin-right: 10px;
		font-size: 1.13em;
		width: 95px;
	}

	p {
		margin: 0;
		font-size: 1.05em;
	}
}

