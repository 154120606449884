.singlePub {

	margin-bottom: 30px;
	display: flex;
	.singlePubInfo {

		margin-bottom: 15px;

		h3 {
			font-family: 'Josefin Sans', sans-serif;
			margin: 0px;
			font-size: 1.3em;
			margin-bottom: 0 0px;
		}
		h4 {
			margin: 0px;
			font-size: 1.15em;
		}
		h5 {
			margin: 0px;
			font-size: 1.1em;
		}

		.authors {
			color: #444444;
		}

		.award {
			color: #f80dad;
			margin-bottom: 4px;
			font-size: 1.1em;

			// font-family: 'Josefin Sans', sans-serif;
		}

		.metadata {
			display: flex;

			.infoCell {
				display: flex;
				.sep {
					margin: 0 5px;
				}
			}
			
		}

		.tldr {
			margin-top: 12px;
			font-size: 1em;
			color: #444444;
		}


	}

	.singlePubImage {
		display: block;

		flex-basis: 210px;
		flex-grow: 0;
		flex-shrink: 0;

		height: 230px;
		margin-right: 30px;
    background-size: cover;
    background-position: 0% 0%;
    margin-bottom: 9px;

		transition: cubic-bezier(0.445, 0.05, 0.55, 0.95);
		transition-duration: 2s;
	}


}

.singlePub:hover {
	.singlePubImage {
		background-position: 100% 0%;
		transition: cubic-bezier(0.445, 0.05, 0.55, 0.95);
		transition-duration: 2s;
	}
}


@media (max-width: 768px) {
	.singlePub {
		display: block;

		.singlePubImage {
			width: 92%;

			margin-bottom: 20px;
		}
	}
}